.App {
  text-align: center;
}

p {
  margin: 0;
  padding: 0;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

::-webkit-scrollbar {
  display: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.blue-box {
  background: #013ade;
  box-shadow: 0px 0px 20px rgba(165, 165, 165, 0.1);
  border-radius: 8px;
  padding-top: 15px;
  padding-bottom: 14px;
  padding-left: 20px;
  padding-right: 25px;
  color: white;
}

.white-box {
  background: white;
  box-shadow: 0px 0px 20px rgba(165, 165, 165, 0.1);
  border-radius: 8px;
  padding-top: 15px;
  padding-bottom: 14px;
  padding-left: 20px;
  padding-right: 25px;
  color: #013ade;
}

.blue-box-2 {
  background: #013ade;
  box-shadow: 0px 0px 20px rgba(165, 165, 165, 0.1);
  border-radius: 8px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 0 1;
  padding: 16px 20px;
}

.blue-box:hover {
  cursor: pointer;
}

.h-100 {
  /* float: right; */
  height: 100%;
  display: flex;
  align-items: center;
}

.vertical-line {
  border-right: 1px solid #e4dcf8;
  display: block;
  height: 100%;
  margin-right: 22px;
}

.ongoing-card {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding-top: 13px;
  padding-bottom: 22px;
  padding-left: 20px;
  padding-right: 16px;
}

.ongoing-card:hover {
  cursor: pointer;
}

.flex-row-center-end {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-col-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.dashed-add {
  border-radius: 4px;
  border: 1px dashed #013ade;
  color: #013ade;
  font-size: 30px;
  text-align: center;
  line-height: 64px;
  margin-top: 1.25rem;
}

.dashed-add:hover {
  cursor: pointer;
}

.status-date {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  color: #21243c;
  padding-top: 12px;
  text-align: center;
}

.lab-test-name {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
  color: #21243c;
}

.order-status {
  background: #fff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  margin-top: 8px;
  border: 1px solid #e3e3e3;
}

.order-status:hover {
  cursor: pointer;
}

.order-status-header {
  background: rgba(101, 68, 198, 0.02);
  border-radius: 8px;
  padding: 12px 16px;
}

.order-status-details {
  padding: 16px;
}

.lab-item-name {
  width: 210px;
}

.lab-image {
  float: right;
  max-width: 94px;
  max-height: 32px;
}

.lab-logo {
  max-width: 94px;
  max-height: 32px;
}

.float-right {
  float: right;
}

.lab-order-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 0;
}

.border-bottom {
  border-bottom: 1px solid #f9f9f9;
}

.search-input {
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: none;
}

.search-input:focus {
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: none;
}

.new-user-input {
  background: #ffffff;
  /* Pineapple #3 */

  border: 1px solid #a5a0b3;
  box-sizing: border-box;
  border-radius: 8px;
  outline: none;
  display: block;
  margin: 20px auto;
  padding: 10px;
  width: 90%;
}

.new-user-input:focus {
  background-image: none;
  background-color: #ffffff;
  outline: none;
  border-color: #013ade;
}

/* .txt-black-500-12{

} */

.full-width {
  width: 100%;
}

.flex-center {
  display: flex;
  align-items: center;
  flex: 1 0;
}

.border-blue {
  border: 1px solid #013ade;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.flex-start-end {
  display: flex;
  width: 100%;
  /* align-items: center; */
  justify-content: space-between;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.hover:hover {
  cursor: pointer;
}

.modalBody {
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  /* padding: 1rem 0.75rem;
  padding-bottom: 0; */
  position: absolute;
  bottom: 0;
  width: 100%;
}

.modalHeader {
  padding: 24px 16px;
  border-bottom: 1px solid #e9e6f2;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modalDetails {
  padding: 16px;
  max-height: 300px;
  overflow-y: auto;
  min-height: 150px;
}

.modalDetails-larger {
  padding: 16px;
  max-height: 450px;
  overflow-y: auto;
  min-height: 150px;
}

.modalContainer {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
}

.patient-container {
  padding: 12px;
  background: #f7f7f7;
  border-radius: 8px;
  margin-bottom: 12px;
  display: flex;
  flex: 1 0;
  align-items: center;
}

.slot-date-container {
  display: flex;
  overflow-x: auto;
  justify-content: space-between;
}

.slot-date {
  border-radius: 12px;
  display: flex;
  flex: 1 0 1;
  align-items: center;
  width: 48px;
  height: 48px;
  justify-content: center;
}

.selected-slot {
  background: #013ade;
}

.flex-row-wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.slot-time {
  border-radius: 8px;
  margin: 12px;
  width: 104px;
  height: 32px;
  text-align: center;
}

.selected-slot-time {
  color: #21243c;
  background: #ffffff;
  border: 1px solid #013ade;
}

.unselected-slot-time {
  color: #302e6b;
  background: #f0f3f6;
  opacity: 0.7;
}

.change-button {
  border: 1px solid #013ade;
  border-radius: 4px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
  text-align: center;
  color: #21243c;
  padding: 4px 18px;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.col {
  flex-direction: column;
}

.grey-body {
  background: #f7f7f7 !important;
}

.radio-input {
  padding: 5px;
}

.radio-input-container {
  display: inline;
  padding: 5px 10px;
  margin: 10px;
  box-shadow: 0px 1px 5px 1px #d1d1d1;
  background: #ffffff;
  border-radius: 8px;
}

.flex-row-full {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 10px;
}

.other-address {
  width: 80px;
  text-align: center;
  background: #e3e3e3;
  color: #aaaaaa;
  font-size: 13px;
  line-height: 20px;
  border-radius: 4px;
}

.selected-address {
  width: 80px;
  text-align: center;
  background: #013ade;
  border-radius: 4px;
  color: #ffffff;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
}

.location-icon {
  position: absolute;
  right: 25px;
}

.lab-home-btn {
  border: 1px solid red;
  border-style: dashed;
  padding: 5px;
  text-align: center;
  margin-top: 25px;
  border-radius: 8px;
}

.lab-home-btn-blue-dashed {
  border: 1px solid #013ade;
  border-style: dashed;
  padding: 5px;
  text-align: center;
  border-radius: 8px;
}

.lab-home-btn-blue {
  border: 1px solid #013ade;
  border-radius: 8px;
  padding: 10px;
  text-align: center;
  margin-top: 25px;
}

.center-align {
  align-items: center;
}

.tests-container {
  background: #d3d6db40;
  border-radius: 4px;
  padding-left: 14px;
  padding-top: 13px;
  padding-bottom: 12px;
  padding-right: 17px;
}

.reimbursement-note {
  font-size: 12px;
  margin: 10px 0;
  padding: 10px;
  background: rgba(176, 152, 227, 0.1);
  color: #21243c;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  flex-shrink: 0;
}

.reimbursement-note-2 {
  background: #4d75e7;
  box-shadow: 0px 0px 20px rgba(165, 165, 165, 0.1);
  border-radius: 8px;
  padding: 24px;
  display: flex;
  color: white;
  align-items: center;
  justify-content: space-between;
}

.bottom-btn {
  width: -webkit-fill-available;
  padding: 16px;
  background: #fefefe;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 8px 8px 0px 0px;
}

.reimbursement-note-3 {
  font-size: 12px;
  margin: 10px 0;
  padding: 10px;
  background: rgba(176, 152, 227, 0.1);
  color: #21243c;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  border-radius: 8px;
}

.check-aqua-image {
  background: #49c8c8;
  border-radius: 50%;
  padding: 10px;
  margin-right: 10px;
  display: flex;
  align-items: center;
}

.border-bottom-grey {
  border-bottom: 1px solid RGBA(203, 203, 203, 0.3);
}

.available-box {
  background: #06c2a9;
  border-radius: 7px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: #ffffff;
  padding: 3px;
  margin-right: 4px;
}

.time-icon {
  background: #916ef7;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-select {
  display: flex;
  flex-direction: column;
  max-height: 100px;
  overflow-y: auto;
  flex: 1 0;
}
.custom-select::-webkit-scrollbar {
  display: block;
  width: 2px;
  max-height: 2px;
  background-color: #aaa;
}

.custom-select-option {
  padding: 10px 0;
  border-bottom: 1px solid #ececec;
  font-size: 12px;
  font-weight: 500;
  color: #21243c;
}

.modal-footer {
  padding: 16px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.doctor-location {
  margin: 5px 10px;
  padding: 3px;
  background: rgba(176, 152, 227, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex: 1 0 auto;
}

.order-lab-prompt {
  margin: 10px;
  padding: 10px;
  background: rgba(176, 152, 227, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex: 1 0 auto;
  align-items: center;
}

.cancel-label {
  font-size: 16px;
  font-weight: 600px;
  color: crimson;
  text-align: center;
}

.cancel-modal-body {
  background: #fff;
  width: 50%;
  height: 30%;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
}

.cancel-modal-button {
  font-size: 16px;
}

.cancel-modal-button-red {
  border: 1px solid red;
  box-shadow: 0px 0px 20px rgba(165, 165, 165, 0.1);
  padding: 5px;
  text-align: center;
  margin-top: 25px;
  border-radius: 8px;
  background: #ffffff;
  color: #21243c;
  font-size: 16px;
  font-weight: 500;
}

.cancel-modal-button-red:hover {
  background: red;
  color: #ffffff;
}

.cancel-modal-button-blue {
  border: 1px solid #013ade;
  box-shadow: 0px 0px 20px rgba(165, 165, 165, 0.1);
  padding: 5px;
  text-align: center;
  margin-top: 25px;
  background: #ffffff;
  color: #21243c;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
}

.cancel-modal-button-blue:hover {
  background: #013ade;
  color: #ffffff;
}

.confirmation-modal-body {
  background: #fff;
  width: 75%;
  max-width: 500px;
  border-radius: 10px;
  text-align: center;
}

.confirmation-modal-note {
  background: rgba(255, 230, 214, 0.6);
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #6b5b51;
  padding: 5px;
}

.reimbursement-pretext {
  background: rgba(208, 219, 237, 0.3);
  border-radius: 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 12px;
  padding: 12px;
  color: #21243c;
}

.reimbursement-category {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

.reimbursement-category-container {
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(165, 165, 165, 0.1);
  border-radius: 8px;
  width: 40%;
  padding: 12px;
  text-align: center;
  margin-top: 16px;
  max-width: 200px;
  min-height: 200px;
}

.reimbursement-category-desc {
  background: #f6f6f6;
  border-radius: 6px;
  padding: 12px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 10px;
  color: #21243c;
}

.reimbursement-request-note {
  display: flex;
  flex-direction: column;
  width: 90%;
  background: rgba(246, 246, 246, 0.1);
  border-radius: 6px;
  padding: 8px 15px;
}

.reimbursement-max-cap {
  background: #ffaf3c;
  border: 0.5px solid #ffaf3c;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 1px 10px;
  margin-left: 5px;
}

.close-icon {
  background: rgba(246, 246, 246, 0.1);
  border-radius: 6px;
  padding: 4px;
  font-size: 16px;
  color: #5334a8;
}

.flex-col-full {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.border-bottom {
  border-bottom: 1px solid #ececec;

  margin-bottom: 4px;
  padding-bottom: 4px;
}

.parter-own-button-blue {
  border: 1px solid #6ebdbd;
  box-shadow: 0px 0px 20px rgba(165, 165, 165, 0.1);
  padding: 10px 5px;
  text-align: center;
  margin-top: 25px;
  background: #ffffff;
  color: #72777a;
  border-radius: 8px;
  font-size: 16px;
  line-height: 19px;
  width: 80%;
  font-weight: 600;
}

.parter-button-blue {
  box-shadow: 0px 0px 20px rgba(165, 165, 165, 0.1);
  padding: 10px 5px;
  text-align: center;
  margin-top: 25px;
  background: #ffffff;
  color: #21243c;
  border-radius: 8px;
  font-size: 16px;
  line-height: 19px;
  background: #00b7b7;
  width: 80%;
  color: white;
  font-weight: 600;
}

.fc_frame_custom_widget {
  display: none !important;
}

.fc-open {
  display: block !important;
}

.support-card {
  background: #013ade15;
  border-radius: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
  padding: 12px;
  padding-right: 25px;
  color: #21243c;
  border-radius: 8px;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.offer-box {
  background: #06c2a9;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  border-radius: 20px;
  color: #ffffff;
  padding: 4px 13px;
  margin-right: 4px;
}

.refer-footer {
  text-align: center;
  padding: 16px;
  border-top: 1px solid #f5f5f5;
}

.profile-padding {
  padding-left: 17px;
  padding-right: 17px;
  padding-top: 14px;
  padding-bottom: 16px;
}

.flex-input-box {
  display: flex;
  flex: 1 0 auto;
  background: #f5f5f5;
  border: 0.5px solid #ededed;
  border-radius: 8px;
  margin-left: 9px;
}

.chat-cancelled-msg {
  background: #ff726820;
  height: 46px;
  line-height: 46px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  border-radius: 6px;
  text-align: center;
  color: #ff7268;
  margin-top: 10px;
}

.border-bottom-dashed {
  border-bottom: 1px dashed #d3d6db;
}

.blue-background {
  background: #5c4cd3;
  box-shadow: 0px 0px 20px rgba(165, 165, 165, 0.1);
  border-radius: 8px;
}

.dark-blue-background {
  background: #4836c8;
  padding-top: 10px;
  padding-bottom: 11px;
}

.pending-status-details {
  padding-top: 9px;
  padding-left: 18px;
  padding-bottom: 14px;
  padding-right: 60px;
}

.disclaimer-background {
  background: #f4fbf7;
  box-shadow: 0px 0px 20px rgba(165, 165, 165, 0.1);
  border-radius: 8px;
}

.text-center {
  text-align: center;
}

.reward-cards-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}

.flex-item {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
}

.reward-header {
  background: #303040;
  position: fixed;
  z-index: 1000;
  width: -webkit-fill-available;
  align-items: center;
  height: 40px;
  padding: 0 16px;
}

.fitcoin-container {
  background: #fff;
  display: flex;
  align-items: center;
  border-radius: 22px;
  flex-direction: row;
  height: 70%;
  padding-right: 20px;
}

.new-address-input {
  background: #ffffff;
  /* Pineapple #3 */

  border: 1px solid #e3e3e3;
  box-sizing: border-box;
  border-radius: 8px;
  outline: none;
  display: block;
  padding: 10px;
  width: 100%;
}

.new-address-input:focus {
  background-image: none;
  background-color: #ffffff;
  outline: none;
  border-color: #3f8cff;
}

.new-address-input::placeholder {
  font-weight: 400;
}

.custom-opacity::before {
  content: '';
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 12px;
}

.expand-enter {
  height: 0px !important;
  overflow: hidden;
}

.expand-enter-active {
  height: 140px !important;
  transition: height 0.3s ease;
}

.expand-exit-active {
  height: 0px !important;
  transition: height 0.3s ease;
  overflow: hidden;
}

.expand-exit {
  height: 0px !important;
  transition: height 0.3s ease;
  overflow: hidden;
}

.clear-float::after {
  content: '';
  clear: both;
  display: table;
}

.journey-success {
  padding: 0 25%;
  justify-content: center;
}
.journey-icon {
  width: 100%;
}
.margint-60px {
  margin-top: 60px;
}
.resp-28px {
  font-size: 28px;
  line-height: 150%;
}
.journey-activated {
  font-size: 22px;
  line-height: 150%;
}
.journey-startusing {
  font-size: 18px;
  line-height: 150%;
}

.react-datepicker-wrapper {
  border: 1px solid #21243c !important;
  margin-top: 10px;
  border-radius: 8px;
}

.react-datepicker-wrapper input {
  text-align: center;
}

.video-local-participant {
  position: absolute;
  width: 300px;
  right: 10px;
  top: 10px;
}

@media only screen and (max-width: 768px) {
  .video-local-participant {
    position: absolute;
    width: 150px;
    right: 10px;
    top: 10px;
  }
}

@media only screen and (max-height: 600px) {
  .journey-success {
    padding: 0 15% !important;
    /* padding-top: 20px; */
  }
  .margint-60px {
    margin-top: 20px;
  }
  .journey-icon {
    width: 75%;
  }
  .resp-28px {
    font-size: 24px;
    line-height: 150%;
  }
  .journey-activated {
    font-size: 18px;
    line-height: 150%;
  }
  .journey-startusing {
    font-size: 15px;
    line-height: 150%;
  }
  .journey-success .mt-32px {
    margin-top: 16px;
  }
}

@media only screen and (max-height: 550px) {
  .journey-success {
    padding: 10% 15% !important;
    justify-content: initial;
  }
}

@keyframes zoominoutsinglefeatured {
  0% {
    transform: scale(0, 0);
  }

  50% {
    transform: scale(0.7, -0.4, 0.4, 1.4);
  }

  100% {
    transform: scale(1, 1);
  }
}

.journey-success {
  animation: zoominoutsinglefeatured 300ms;
}

.flex-dual {
  width: 48%;
}

@media only screen and (max-width: 320px) {
  .flex-dual {
    width: 100%;
  }
}

.ios-fix {
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-flex: 0;
  flex-shrink: 0;
}

.reimbursement-input {
  border: none !important;
  background-image: none;
  background: #f6f6f6;
  border-radius: 6px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: none;
  padding: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  width: 95%;
}

.reimbursement-input:focus {
  border: none;
  background-image: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: none;
}

.reimbursement-input::placeholder {
  color: #d8d8d8;
}

.reimbursement-input input {
  background-color: #f6f6f6;
  width: 100%;
  text-align: left;
}

.hra-button {
  border-radius: 40px !important;
  padding: 16px 20px !important;
}

.reimbursement-input-date {
  background: #ffffff;
  background-image: none;
  border-radius: 6px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: none;
  padding: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  width: 100%;
  height: 36px;
  border: 1px solid #6544c6;
  padding-left: 16px;
  padding-right: 16px;
}

.reimbursement-input-date:focus {
  border: 1px solid #6544c6 !important;
  background-image: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: none;
}

.reimbursement-input-date::placeholder {
  color: #d8d8d8;
}

.reimbursement-input-date input {
  background-color: #ffffff;
  width: 100%;
  text-align: left;
  font-family: Manrope;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
  color: #21243c;
}

.reimbursement-input-date input:focus {
  border: none;
  background-image: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: none;
}
::-webkit-scrollbar-button {
  display: none;
}
::-webkit-scrollbar-track {
  display: none;
}
::-webkit-scrollbar-track-piece {
  display: none;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: #5e6c84;
}
::-webkit-scrollbar-corner {
  display: none;
}
::-webkit-resizer {
  display: none;
}

.custom-scrollbar::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 4px;
  margin-left: -10px;
  display: inherit !important;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.col {
  flex-basis: 0;
  -webkit-box-flex: 1;
  flex-grow: 1;
  max-width: 100%;
}

.chart1 {
  width: 100%;
}

.chart {
  width: 100%;
}

svg text {
  font-family: 'brandonRegular';
}

svg.activity-progress-svg {
  border-radius: 50%;
  background: #fff;
}

svg.individual-chart defs #svgGradient #offset0 {
  stop-color: #5fe1ff;
}

svg.individual-chart defs #svgGradient #offset100 {
  stop-color: #8b95ff;
}

svg.new-nutrition-progress defs #totalGradient #offset0 {
  stop-color: #06c2a9;
}

svg.new-nutrition-progress defs #totalGradient #offset100 {
  stop-color: #06c2a9;
}
svg.new-carbs-progress defs #carbsGradient #offset0 {
  stop-color: #ff7268;
}

svg.new-carbs-progress defs #carbsGradient #offset100 {
  stop-color: #ff7268;
}
svg.new-protein-progress defs #proteinGradient #offset0 {
  stop-color: #244cb2;
}

svg.new-protein-progress defs #proteinGradient #offset100 {
  stop-color: #244cb2;
}
svg.new-fats-progress defs #fatsGradient #offset0 {
  stop-color: #ffa800;
}

svg.new-fats-progress defs #fatsGradient #offset100 {
  stop-color: #ffa800;
}

svg.individual-chart defs #nutritionGradient #offset0,
svg.nutrition-progress defs #totalGradient #offset0,
svg.carbs-progress defs #carbsGradient #offset0,
svg.protein-progress defs #proteinGradient #offset0,
svg.fats-progress defs #fatsGradient #offset0 {
  stop-color: #4be644;
}

svg.individual-chart defs #nutritionGradient #offset100,
svg.nutrition-progress defs #totalGradient #offset100,
svg.carbs-progress defs #carbsGradient #offset100,
svg.protein-progress defs #proteinGradient #offset100,
svg.fats-progress defs #fatsGradient #offset100 {
  stop-color: #42cb3b;
}

svg.carbs defs #svgGradient #offset0 {
  stop-color: #4be644;
}

svg.carbs defs #svgGradient #offset100 {
  stop-color: #42cb3b;
}

svg.team-chart defs #teamGradient #offset0 {
  stop-color: #c4e491;
}

svg.team-chart defs #teamGradient #offset100 {
  stop-color: #76cb7a;
}

svg.org-chart defs #orgGradient #offset0 {
  stop-color: #8cf7c9;
}

svg.org-chart defs #orgGradient #offset100 {
  stop-color: #2aceea;
}

svg.steps-chart defs #stepsGradient #offset0 {
  stop-color: #5fe1ff;
}

svg.steps-chart defs #stepsGradient #offset100 {
  stop-color: #8b95ff;
}

svg.hra-chart defs #hraGradient #offset0 {
  stop-color: #5fe1ff;
}

svg.hra-chart defs #hraGradient #offset100 {
  stop-color: #8b95ff;
}

svg.cals-chart defs #calsGradient #offset0 {
  stop-color: #c4e491;
}

svg.cals-chart defs #calsGradient #offset100 {
  stop-color: #76cb7a;
}

svg.sleep-chart defs #sleepGradient #offset0 {
  stop-color: #3a2ca0;
}

svg.sleep-chart defs #sleepGradient #offset100 {
  stop-color: #3a2ca0;
}

svg.chart defs #barGradient #offset0,
svg.chart1 defs #barGradient1 #offset0 {
  stop-color: #4d75e7;
}

svg.chart defs #barGradient #offset100,
svg.chart1 defs #barGradient1 #offset100 {
  stop-color: #4d75e7;
}

svg.chart defs #barGradient #distanceoffset0 {
  stop-color: #4d75e7;
}

svg.chart defs #barGradient #distanceoffset100 {
  stop-color: #4d75e7;
}

svg.chart defs #barGradient #caloriesoffset0 {
  stop-color: #4d75e7;
}

svg.chart defs #barGradient #caloriesoffset100 {
  stop-color: #4d75e7;
}

svg.chart defs #barGradient #stepsoffset0 {
  stop-color: #4d75e7;
}

svg.chart defs #barGradient #stepsoffset100 {
  stop-color: #4d75e7;
}

svg.chart defs #barGradient #nutritionoffset0 {
  stop-color: #06c2a9;
}

svg.chart defs #barGradient #nutritionoffset100 {
  stop-color: #06c2a9;
}

svg.chart defs #barGradient #sleepoffset0 {
  stop-color: #3a2ca0;
}

svg.chart defs #barGradient #sleepoffset100 {
  stop-color: #3a2ca0;
}

svg.chart defs #comparitiveBarGradient #offset0,
svg.chart1 defs #comparitiveBarGradient #offset0 {
  stop-color: #ff9d29;
}

svg.chart defs #comparitiveBarGradient #offset100,
svg.chart1 defs #comparitiveBarGradient #offset100 {
  stop-color: #ffba77;
}

svg.chart .Fasting-circle {
  fill: #8a35f4;
}

svg.chart .Random-circle {
  fill: #3bc9bb;
}

svg.chart .Post-meal-circle {
  fill: #f35a79;
}

svg.chart .Systolic-circle {
  fill: #64efcf;
}

svg.chart .Diastolic-circle {
  fill: #4191ff;
}

svg.chart .Weight-circle {
  fill: #985de4;
}

svg.chart .wc-circle {
  fill: #4aa4f3;
}

svg.chart .hemo-circle {
  fill: #d4145a;
}

svg.chart .chol-circle {
  fill: #ff396f;
}

svg.chart1 defs #barGradient1 #distanceoffset0 {
  stop-color: #4d75e7;
}

svg.chart1 defs #barGradient1 #distanceoffset100 {
  stop-color: #4d75e7;
}

svg.chart1 defs #barGradient1 #caloriesoffset0 {
  stop-color: #4d75e7;
}

svg.chart1 defs #barGradient1 #caloriesoffset100 {
  stop-color: #4d75e7;
}

svg.chart1 defs #barGradient1 #stepsoffset0 {
  stop-color: #4d75e7;
}

svg.chart1 defs #barGradient1 #stepsoffset100 {
  stop-color: #4d75e7;
}

svg.chart1 defs #barGradient1 #nutritionoffset0 {
  stop-color: #06c2a9;
}

svg.chart1 defs #barGradient1 #nutritionoffset100 {
  stop-color: #06c2a9;
}

svg.chart1 defs #barGradient1 #sleepoffset0 {
  stop-color: #3a2ca0;
}

svg.chart1 defs #barGradient1 #sleepoffset100 {
  stop-color: #3a2ca0;
}

svg.chart1 defs #comparitiveBarGradient #offset0 {
  stop-color: #ff9d29;
}

svg.chart1 defs #comparitiveBarGradient #offset100 {
  stop-color: #ffba77;
}

svg.chart1 .Fasting-circle {
  fill: #8a35f4;
}

svg.chart1 .Random-circle {
  fill: #3bc9bb;
}

svg.chart1 .Post-meal-circle {
  fill: #f35a79;
}

svg.chart1 .Systolic-circle {
  fill: #64efcf;
}

svg.chart1 .Diastolic-circle {
  fill: #4191ff;
}

svg.chart1 .Weight-circle {
  fill: #985de4;
}

svg.chart1 .wc-circle {
  fill: #4aa4f3;
}

svg.chart1 .hemo-circle {
  fill: #d4145a;
}

svg.chart1 .chol-circle {
  fill: #ff396f;
}

.data-svg.steps-svg svg path {
  fill: #4d75e7 !important;
}

.goal-svg.steps-goal svg path,
.data-svg.distance-svg svg path {
  fill: #27ae60 !important;
}
.activity-svg.steps-activity svg path,
.activity-svg.distance-activity svg path,
.activity-svg.calories-activity svg path {
  fill: #2871ea !important;
}

.goal-svg.distance-goal svg path {
  fill: #27ae60 !important;
}

.data-svg.calories-svg svg path {
  fill: #916ef7 !important;
}

.goal-svg.calories-goal svg path {
  fill: #27ae60 !important;
}

/*g.axis {*/
/*font-size: 1rem;*/
/*}*/

g.y.axis {
  stroke-width: 0;
}

g.y.axis .tick text {
  opacity: 0;
}

g.y.detailed.sleep.axis .tick text {
  opacity: 0.3;
}

g.x.axis .tick line {
  visibility: hidden;
}

g.x.axis .domain {
  stroke: #8852fc;
  stroke-opacity: 0.8;
}

g.steps.x.axis .domain {
  stroke: #72b0ff;
}

g.calories.x.axis .domain {
  stroke: #ff85af;
}

g.y.axis .tick:nth-of-type(2n + 1) text {
  font-size: 9px;
  opacity: 0.3;
}

g.y.axis .tick:first-of-type text,
g.y.axis .tick:last-of-type text {
  font-weight: 600;
  opacity: 1;
}

g.y.detailed.sleep.axis .tick:last-of-type text {
  font-weight: 600;
  opacity: 1;
}

g.detailed.y.axis .tick:first-of-type text {
  opacity: 0;
}

g.detailed.x.axis .domain {
  stroke: none;
}

.grid .tick {
  opacity: 0.1;
  stroke: lightgrey;
}

.grid path {
  stroke-width: 0;
}

.grid .tick:first-of-type {
  display: none;
}

.d-none {
  display: none;
}

.water-goal::first-letter {
  font-size: 18px;
}

.slider {
  height: 2px;
}

.slider {
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%; /* Full-width */
  height: 2px; /* Specified height */
  background: #0095df; /* Grey background */
  outline: none; /* Remove outline */
  opacity: 1; /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: 0.2s; /* 0.2 seconds transition on hover */
  transition: opacity 0.2s;
  z-index: 25;
}

/* Mouse-over effects */
.slider:hover {
  opacity: 1; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 30px; /* Set a specific slider handle width */
  height: 30px; /* Slider handle height */
  background: #0095df; /* Green background */
  cursor: pointer; /* Cursor on hover */
  border-radius: 50%;
}

.slider::-moz-range-thumb {
  width: 2px; /* Set a specific slider handle width */
  height: 2px; /* Slider handle height */
  background: #0095df; /* Green background */
  cursor: pointer; /* Cursor on hover */
}

.background-transition {
  transition: background 500ms ease;
}

.css-1nrl0ya {
  height: 100%;
}

.back-to-app {
  background-color: #3a5c81;
  width: 100%;
  padding: 8px 4px;
  height: fit-content;
  display: flex;
}

.back-to-app p {
  font-size: 12px;
  line-height: 15px;
  color: #fff;
  margin: 0;
  margin-left: 4px;
  font-weight: 600;
}
